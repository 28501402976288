import React, { Component } from 'react'
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {
  Grid,
  Card,
  Image,
  H3,
  Tag
} from '@maggioli-design-system/react'
//import ModaleSpostamento from '../../ModaleSpostamento/ModaleSpostamento'
import './ContenitoriSortable.css'

import noImage from '../../assets/images/default.png'

const SortableItem = SortableElement(({value, sortIndex, toggle}) =>
  <Card key={value._key} className={value.disponibile === false ? "background-color-status-warning-14" : ""}>
    <div className="Hidden">{value._key}</div>
    <Grid columns="2" className={"text-secondary text-secondary--detail"}>
      <Tag chip={true} icon="paginator-next" className={value.type==="opera" ? "color-status-success-05 background-color-status-success-19" : "color-status-warning-05 background-color-status-warning-19"}>{sortIndex+1}</Tag>
      <Tag chip={true} icon={value.type==="opera" ? "design-palette" : "form-checkbox-unchecked"} size="small"
          className={value.type==="opera" ? "capitalized color-status-success-05 background-color-status-success-17" : "capitalized color-status-warning-05 background-color-status-warning-17"}>
        {value.disponibile === false ? value.type + " Spostata" : value.type}
      </Tag>
    </Grid>
    <Grid>
      <Image src={value.media ? value.media.immagine : noImage} aspectRatio="4:3" aspectRatioPosition="50% 50%" />
    </Grid>
    <H3 className="capitalized">{value.nome}</H3>
  </Card>
)

const SortableList = SortableContainer(({items, toggle}) => {
  return (
    <Grid columns="3">
      {items.map((value, index) => (
        <SortableItem key={`item-${value._key}`} index={index} value={value} sortIndex={index} toggle={toggle} />
      ))}
    </Grid>
  )
})

class OpereSortable extends Component {

  state = {
    show: false,
    _key: null
  }

  toggleModal = (_key, disponibile) => {
    this.setState({
      show: !this.state.show,
      _key: _key,
      disponibile: disponibile
    })
  }

  render() {
    return (
      <div>
        <SortableList pressDelay={200} useWindowAsScrollContainer helperClass="background-color-status-info-18 box-shadow-soft" items={this.props.data} onSortEnd={this.props.onSortEnd} toggle={this.toggleModal} axis="xy"/>
      </div>
      )
  }
}

export default OpereSortable