import { GET_SUCCESS, CLEAR_SUCCESS} from './types';

export const returnSuccess = (message, status, id = null) => {
    return {
        type: GET_SUCCESS,
        payload: {message, status, id}
    }
}

export const clearSuccess = () => {
    return {
        type: CLEAR_SUCCESS
    }
}