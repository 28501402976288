import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { getSegnalazione, commentSegnalazione, clearSegnalazione } from '../actions/segnalazioniActions'
import {clearErrors} from '../actions/errorActions'
import {clearSuccess} from '../actions/successActions'
import {parseData} from '../utils/Utils'
import Loader from './Loader'
import {
    Grid,
    Card,
    Row,
    H2,
    H3,
    H4,
    Hr,
    Tag,
    UList,
    UListItem,
    Banner,
    Textarea,
    Button,
    Image
} from '@maggioli-design-system/react'
import noImage from '../assets/images/default.png'

class Segnalazione extends Component {

    state= {
        message: null,
        success: null,
        commento: null
    }
    
    componentDidMount(){
        this.props.getSegnalazione(this.props.match.params._key)
    }
    componentWillUnmount(){
        this.props.clearSegnalazione();
    }

    componentDidUpdate(prevProps) {
        const {error} = this.props;
        const {success} = this.props;
        if(error !== prevProps.error){
            if(error.id === 'SEGNALAZIONE_ERROR'){
                this.setState({message: error.message.message})
            } else {
                this.setState({message: null})
            }
        }
        if(success !== prevProps.success){

            if(success.id === 'SEGNALAZIONE_UPDATE_SUCCESS'){
                this.setState({success: success.message})
            } else {
                this.setState({success: null})
            }
        }
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    sendComment = () => {
        let  commento = {
            "commento": this.state.commento
        }
        this.props.commentSegnalazione(this.props.match.params._key, commento)
        this.setState({commento: null})
    }

    renderMessage = () => {
        if(this.state.message){
            return <Banner status="error">{this.state.message}</Banner>
        }
        else if(this.state.success){
            return <Banner status="success">{this.state.success}</Banner>
        }
    }

    render(){
        const {segnalazione} = this.props.segnalazione;
        if(segnalazione && segnalazione._key){
            return (
                <div>
                    <Loader/>
                    <Card gutter="large" key={segnalazione._key} className={segnalazione.convalida === 1 ? "background-color-status-info-19 box-shadow-soft" : (segnalazione.stati.length === 1 ? "background-color-status-error-19 box-shadow-soft" : "background-color-status-warning-19 box-shadow-soft")}>
                        <Grid gutter="xsmall" columns="2">
                            <H2>{segnalazione.oggetto.nome}</H2>
                            <Row lastChild="to-right" gutter="large">

                                {segnalazione.stati.length === 1 ?
                                    <Tag chip={true} icon="status-warning" className={`background-color-status-error-17 color-status-error-04`}>Aperta</Tag>
                                    : (segnalazione.stati.length === 2 ? 
                                    <Tag chip={true} icon="help-support" className={`background-color-status-warning-17 color-status-warning-04`}>In carico</Tag>
                                    : 
                                    <Tag chip={true} icon="crud-save" className={`background-color-status-info-17 color-status-info-04`}>Risolta</Tag>)
                                }
                            </Row>
                        </Grid>
                        <Grid>
                            {segnalazione.descrizione ? 
                                    <H4>{segnalazione.descrizione}</H4>
                                : null}
                            <UList>
                            {segnalazione.danni && segnalazione.danni.map(danno => (
                                <UListItem key={danno} icon="status-error" iconSize="small" iconClassName="color-status-error-08">{danno}</UListItem>
                            ))}
                            </UList>
                            <Card key={segnalazione._key}>
                                <H3>Stati:</H3>
                                {segnalazione.stati.map(stato=>(
                                    <Card gutter="xxsmall" key={stato.data} className={stato.stato === "Risolta" ? "background-color-status-info-19 box-shadow-soft" : (stato.stato === "Aperta" ? "background-color-status-error-19 box-shadow-soft" : "background-color-status-warning-19 box-shadow-soft")}>
                                        <Grid columns="2">
                                            <Tag className={stato.stato === "Risolta" ? "color-status-info-04" : (stato.stato === "Aperta" ? "color-status-error-04" : "color-status-warning-04")}
                                                icon={stato.stato === "Risolta" ? "crud-save" : (stato.stato === "Aperta" ? "status-warning" : "help-support")}>
                                                    {stato.stato}
                                            </Tag>
                                            <Grid columns="2">
                                                <Tag icon="input-calendar">{parseData(stato.data).data}</Tag>
                                                <Tag icon="input-time" size="small">{parseData(stato.data).ora}</Tag>
                                            </Grid>
                                        </Grid>
                                        <Hr className={stato.stato === "Risolta" ? "background-color-status-info-18" : (stato.stato === "Aperta" ? "background-color-status-error-18" : "background-color-status-warning-18")} />
                                        <Grid columns="2">
                                                <Tag icon={stato.utente.ruolo === "CUSTODE" ? "role-subscriber" : (stato.utente.ruolo === "COORDINATORE" ? "role-admin" : "role-editor")}>
                                                    <H4>{stato.utente.info.nome} {stato.utente.info.cognome}</H4>
                                                </Tag>
                                                <Grid columns="2">
                                                    <Tag icon="action-phone"><a href={`tel:${stato.utente.info.telefono}`}>{stato.utente.info.telefono}</a></Tag>
                                                    <Tag icon="document-email"><a href={`mailto:${stato.utente.info.email}`}>{stato.utente.info.email}</a></Tag>
                                                </Grid>
                                        </Grid>
                                        {stato.tipo_danno ? 
                                            <Grid gutter="xsmall">
                                                <Hr className={stato.stato === "Risolta" ? "background-color-status-info-18" : (stato.stato === "Aperta" ? "background-color-status-error-18" : "background-color-status-warning-18")} />
                                                <Grid columns="2">
                                                    <Tag>Tipologia Danno: <H4>{stato.tipo_danno}</H4></Tag>
                                                    {stato.annotazioni ?
                                                        <Tag>Annotazioni: <H4>{stato.annotazioni}</H4></Tag>
                                                    :
                                                    null}
                                                    <Tag></Tag>
                                                </Grid>
                                            </Grid> 
                                            :
                                            null
                                        }
                                    </Card>
                                ))}
                            </Card>
                        </Grid>
                        <Grid>
                            <Card className="background-color-status-info-20">
                                <H3>Commenti:</H3>
                                {segnalazione.commenti.length > 0 ? 
                                    segnalazione.commenti.map(commento=>(
                                        <Card className="background-color-status-info-17" key={commento.data}>
                                            <Grid columns="2">
                                                <Tag icon={commento.utente.ruolo === "CUSTODE" ? "role-subscriber" : (commento.utente.ruolo === "COORDINATORE" ? "role-admin" : "role-editor")}>
                                                    <H4>{commento.utente.info.nome} {commento.utente.info.cognome}</H4>
                                                </Tag>
                                                <Grid columns="2">
                                                    <Tag icon="input-calendar">{parseData(commento.data).data}</Tag>
                                                    <Tag icon="input-time" size="small">{parseData(commento.data).ora}</Tag>
                                                </Grid>
                                            </Grid>
                                            <Card className={"box-shadow-none background-color-status-info-18"}>
                                                {commento.commento}
                                            </Card>
                                        </Card>
                                    ))
                                : <H4>Nessun commento</H4>}
                                <Hr className="background-color-status-info-19" />
                                {segnalazione.stati.length < 3 && 
                                    <Card className="background-color-status-info-20" gutter="normal">
                                        <Textarea name="commento" value={this.state.commento} onChange={this.onChange} placeholder="Scrivi un commento..." />
                                        {this.renderMessage()}
                                        <Button variant="info" onClick={this.sendComment}>Invia</Button>
                                    </Card>
                                }
                            </Card>
                        </Grid>
                        <Grid columns="2">
                            <div>
                                <Image src={segnalazione.oggetto.media ? segnalazione.oggetto.media : noImage} sourceTitle="Immagine" aspectRatio="16:9" aspectRatioPosition="50% 50%" />
                            </div>
                            <div>
                                <Image src={segnalazione.media ? segnalazione.media : noImage} aspectRatio="16:9" sourceTitle={segnalazione.media ? "Allegato Segnalazione" : "Nessun allegato segnalazione"} aspectRatioPosition="50% 50%" />
                            </div>
                        </Grid>
                    </Card>
                </div>
            )
        }else{
            return <h1>Errore nella segnalazione</h1>
        }
        
    }
}

Segnalazione.propTypes = {
    getSegnalazione: PropTypes.func.isRequired,
    segnalazione: PropTypes.object.isRequired,
    commentSegnalazione: PropTypes.func.isRequired,
    clearSegnalazione: PropTypes.func.isRequired,
    error: PropTypes.object.isRequired,
    success: PropTypes.object.isRequired,
    clearErrors: PropTypes.func.isRequired,
    clearSuccess: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    segnalazione: state.segnalazione,
    error: state.error,
    success: state.success
});

export default connect(mapStateToProps, {getSegnalazione, commentSegnalazione, clearSegnalazione, clearErrors, clearSuccess})(Segnalazione)