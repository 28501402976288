import { 
    GET_USER,
    UPDATE_USER,
    DELETE_USER,
    ADD_USER,
    USER_LOADING,
    USERS_ERROR ,
    CLEAR_USER
} from '../actions/types';

const initialState = {
    user: {},
    loading: false
};

export default function usr(state = initialState, action){

    switch(action.type){
        
        case GET_USER:
            return {
                ...state,
                user: action.payload,
                loading: false
            }
        
        case UPDATE_USER:
        case ADD_USER:
            return {
                ...state,
                loading: false
            }
        
        case DELETE_USER:
            return {
                ...state,
                users: state.users.filter(user => user._key !== action.payload)
            }

        case USER_LOADING:
            return {
                ...state,
                loading: true
            }

        case USERS_ERROR:
            return {
                ...state,
                loading: false
            }

        case CLEAR_USER:
            return {
                ...state,
                user: null
            }

        default:
            return state;
    }
}

