import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import {persistStore} from 'redux-persist'
import rootReducer from './reducers'

const initialState = {}

const middleware = [thunk]

const middlewareCompose = process.env.NODE_ENV === 'development' ? 
    compose(
        applyMiddleware(...middleware),
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    ) : 
    compose(
        applyMiddleware(...middleware)
    )

export const store = createStore(rootReducer, initialState, middlewareCompose)

export const persistor = persistStore(store)

const obj = {store, persistor}

export default obj