import axios from 'axios';
import {url} from '../config/Config'
import {
    GET_SALA, 
    SALA_LOADING,
    SALA_ERROR, 
    REORDER_OPERE_LIST, 
    RONDA_SET_OPERE, 
    GET_MOVEMENT, 
    GET_MOVEMENT_FAIL,
    UPDATE_MOVEMENT,
    DELETE_MOVEMENT,
    MOVE_OPERA, 
    MOVE_OPERA_FAIL, 
    CLEAR_MOVEMENT
} from './types'
import {tokenConfig} from './config';
import { returnSuccess } from './successActions';
import { returnErrors } from './errorActions';

export const getSala = _keysala => (dispatch, getState) => {
    dispatch(setSalaLoading());
    axios
        .get(`${url}/api/v1/sale/get/${_keysala}`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_SALA,
                payload: res.data.data
            })
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status, 'SALA_ERROR'));
            dispatch({
                type: SALA_ERROR
            })
        })
}

export const rondaSetOpere = opere => (dispatch, getState) => {
    axios
        .post(`${url}/api/v1/ronda/opere`, opere, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: RONDA_SET_OPERE
            })
            dispatch(returnSuccess(res.data.message, res.status, 'RONDA_UPDATE_SUCCESS'))
        })
        .catch(err => {
            console.log(err)
            dispatch(returnErrors(err.response.data, err.response.status, 'RONDA_ERROR'));
            dispatch({
                type: SALA_ERROR
            })
        })
}

export const orderOpereList = (oldIndex, newIndex) => (dispatch, getState) => {
    dispatch(setSalaLoading())
    dispatch({
        type: REORDER_OPERE_LIST,
        payload: {oldIndex, newIndex},
    })
    let newOrder = []
    newIndex.target.childNodes.forEach(c => {
        let obj = c.innerText.includes("Opera") ? 
            {
                type: "opera",
                _key: c.firstChild.innerHTML
            } :
            {
                type: "contenitore",
                _key: c.firstChild.innerHTML
            }
        newOrder.push(obj)
    })
    dispatch(rondaSetOpere(newOrder))
}

export const getMovement = _key => (dispatch, getState) => {
    axios
    .get(`${url}/api/v1/opere/move?_key=${_key}`, tokenConfig(getState))
    .then(res => {
        dispatch({
            type: GET_MOVEMENT,
            payload: res.data.data
        })
    })
    .catch(err => {
        console.log(err)
        dispatch({
            type: GET_MOVEMENT_FAIL
        })
    })
}

export const moveOpera = movement => (dispatch, getState) => {
    axios
        .post(`${url}/api/v1/opere/move`, movement, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: MOVE_OPERA,
                payload: res.data.data
            })
            dispatch(returnSuccess(res.data.message, res.status, 'MOVE_OPERA_SUCCESS'))
        })
        .catch(err => {
            console.log(err)
            dispatch(returnErrors(err.response.data, err.response.status, 'MOVE_OPERA_FAIL'))
            dispatch({
                type: MOVE_OPERA_FAIL
            })
        })
}

export const updateMovement = movement => (dispatch, getState) => {
    axios
        .put(`${url}/api/v1/opere/move`, movement, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_MOVEMENT,
                payload: res.data.data
            })
            dispatch(returnSuccess(res.data.message, res.status, 'MOVE_OPERA_SUCCESS'))
        })
        .catch(err => {
            console.log(err)
            dispatch(returnErrors(err.response.data, err.response.status, 'MOVE_OPERA_FAIL'))
            dispatch({
                type: MOVE_OPERA_FAIL
            })
        })
}

export const deleteMovement = _key => (dispatch, getState) => {
    axios
        .delete(`${url}/api/v1/opere/move/${_key}`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: DELETE_MOVEMENT
            })
            dispatch(returnSuccess(res.data.message, res.status, 'MOVE_OPERA_SUCCESS'))
        })
        .catch(err => {
            console.log(err)
            dispatch(returnErrors(err.response.data, err.response.status, 'MOVE_OPERA_FAIL'))
            dispatch({
                type: MOVE_OPERA_FAIL
            })
        })
}

export const setSalaLoading = () => {
    return {
        type: SALA_LOADING
    }
}

export const clearMovement = () => (dispatch, getState) => {
    dispatch({
        type: CLEAR_MOVEMENT
    })
}