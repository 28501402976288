import React, { Component } from 'react'
import {connect} from 'react-redux';
import {Link} from "react-router-dom"
import {
    Grid,
    Card,
    H2,
    H3,
    Icon,
    Row,
    Hr,
    Tag
} from '@maggioli-design-system/react'

class Welcome extends Component {
    render() {
        return (
            <Grid>
                <Grid columns="4">
                    <Tag chip icon="menu-main" size="xlarge"><H3>Menu:</H3></Tag>
                    {this.props.loggedUser.ruolo === "COORDINATORE" ? 
                    <Link to="/utenti">
                        <Card className="hover:bg-brand-argo-20">
                            <Row>
                            <Icon name="user" size="large" className="text-brand-argo-0" /><H3 className="text-brand-argo-6">Utenti</H3>
                            </Row>
                        </Card>
                    </Link> : null }
                    {this.props.loggedUser.ruolo === "CONSERVATORE" ? 
                    <Link to="/ronda">
                        <Card className="hover:bg-brand-argo-20">
                            <Row>
                            <Icon name="map-directions" size="large" className="text-brand-argo-0" /><H3 className="text-brand-argo-6">Ronda</H3>
                            </Row>
                        </Card>
                    </Link> : null}
                    <Link to="/segnalazioni">
                        <Card className="hover:bg-brand-argo-20">
                            <Row>
                            <Icon name="notifications" size="large" className="text-brand-argo-0" /><H3 className="text-brand-argo-6">Segnalazioni</H3>
                            </Row>
                        </Card>
                    </Link>
                </Grid>
                <Hr />
                <Grid columns="4">
                    <Tag chip icon="tips" size="xlarge"><H3>Tutorials:</H3></Tag>
                    {/*<Link to="/tutorials-doc">
                        <Card className="hover:bg-brand-argo-20">
                            <Row>
                            <Icon name="document-text" size="large" className="text-brand-argo-0" /><H3 className="text-brand-argo-6">Documentazione</H3>
                            </Row>
                        </Card>
                    </Link> */}
                    <Link to="/tutorials-custode">
                        <Card className="hover:bg-brand-argo-20">
                            <Row>
                            <Icon name="role-subscriber" size="large" className="text-brand-argo-0" /><H3 className="text-brand-argo-6">Custode</H3>
                            </Row>
                        </Card>
                    </Link>
                    <Link to="/tutorials-conservatore">
                        <Card className="hover:bg-brand-argo-20">
                            <Row>
                            <Icon name="role-editor" size="large" className="text-brand-argo-0" /><H3 className="text-brand-argo-6">Conservatore</H3>
                            </Row>
                        </Card>
                    </Link>
                </Grid>
                <Hr />
                <Grid columns="4">
                    <div></div>
                    <div></div>
                    <div></div>
                    <Link to="/download">
                        <Card className="hover:bg-brand-argo-20">
                            <Row>
                            <Icon name="action-download" size="large" className="text-brand-argo-0" /><H2 className="text-brand-argo-6">Download App</H2>
                            </Row>
                        </Card>
                    </Link>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => ({
    loggedUser: state.auth.user
})
export default connect(mapStateToProps)(Welcome)
