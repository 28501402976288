import React, { Component, Fragment } from 'react'
import { Switch, Route, withRouter } from "react-router-dom";
import {connect} from 'react-redux';
import PropTypes from 'prop-types'
import {logout} from '../actions/authActions'

import Users from './Users'
import User from './User'
import CreateUser from './CreateUser'
import Localizzazioni from './Ronda/Localizzazioni'
import Piante from './Ronda/Piante'
import Ronda from './Ronda/Ronda'
import Segnalazioni from './Segnalazioni'
import Segnalazione from './Segnalazione'
import Login from './Login'
import Welcome from './Welcome'
import {
    TutorialsDoc,
    TutorialsCustode,
    TutorialsConservatore
} from './Tutorials'
import {BackofficeMenuItemAction, BackofficeMenuItemTitle} from './Custom/BackofficeMenu'
import {
    BackofficeMenu,
    BackofficeMenuItem,
    InputText
} from '@maggioli-design-system/react'
import BackofficePage, {
    BackofficePageAside,
    BackofficePageSectionHeader,
    BackofficePageAsideFooter,
    BackofficePageSection,
    BackofficePageContent,
} from './Custom/BackofficePage'
import DownloadApp from './DownloadApp';
import UsersDisabled from './UsersDisabled';

class Admin extends Component {

    onExit = history => {
        history.push('/')
        this.props.logout()
    }

    render() {
        const {location, history} = this.props

        return (
                <BackofficePage>
                    <BackofficePageAside title="Argo" description="Maggiolicloud" logo={true}>
                        <BackofficeMenu>
                            {this.props.loggedUser.ruolo === "COORDINATORE" ? 
                            <Fragment>
                            <BackofficeMenuItem>
                                <BackofficeMenuItemTitle icon="user-department">
                                    Utenti
                                    <BackofficeMenuItemAction to="/utenti" icon="data-view" active={location.pathname.split('/')[1] === 'utenti'}>
                                        Gestisci
                                    </BackofficeMenuItemAction>
                                    <BackofficeMenuItemAction to="/nuovo-utente" icon="data-add" active={location.pathname.split('/')[1] === 'nuovo-utente'}>
                                        Aggiungi
                                    </BackofficeMenuItemAction>
                                    <BackofficeMenuItemAction to="/utenti-disabilitati" icon="action-close" active={location.pathname.split('/')[1] === 'utenti-disabilitati'}>
                                        Disabilitati
                                    </BackofficeMenuItemAction>
                                </BackofficeMenuItemTitle>
                            </BackofficeMenuItem>
                            </Fragment>
                            : <Fragment/>}
                            {this.props.loggedUser.ruolo === "CONSERVATORE" ? 
                            <BackofficeMenuItem>
                                <BackofficeMenuItemTitle icon="map-directions">
                                    Ronda
                                    <BackofficeMenuItemAction to="/ronda" icon="data-view" active={location.pathname.split('/')[1] === 'ronda'}>
                                        Gestisci
                                    </BackofficeMenuItemAction>
                                </BackofficeMenuItemTitle>
                            </BackofficeMenuItem>
                            : <Fragment/>}
                            <BackofficeMenuItem>
                                <BackofficeMenuItemTitle icon="notifications">
                                    Segnalazioni
                                    <BackofficeMenuItemAction to="/segnalazioni" icon="data-view" active={location.pathname.split('/')[1] === 'segnalazioni'}>
                                        Gestisci
                                    </BackofficeMenuItemAction>
                                </BackofficeMenuItemTitle>
                            </BackofficeMenuItem>
                            <BackofficeMenuItem>
                                <BackofficeMenuItemTitle icon="tips">
                                    Tutorials
                                    {/*<BackofficeMenuItemAction to="/tutorials-doc" icon="document-text" active={location.pathname.split('/')[1] === 'tutorials-doc'}>
                                        Documentazione
                                    </BackofficeMenuItemAction>*/}
                                    <BackofficeMenuItemAction to="/tutorials-custode" icon="role-subscriber" active={location.pathname.split('/')[1] === 'tutorials-custode'}>
                                        Custode
                                    </BackofficeMenuItemAction>
                                    <BackofficeMenuItemAction to="/tutorials-conservatore" icon="role-editor" active={location.pathname.split('/')[1] === 'tutorials-conservatore'}>
                                        Conservatore
                                    </BackofficeMenuItemAction>
                                </BackofficeMenuItemTitle>
                            </BackofficeMenuItem>
                            <BackofficeMenuItem>
                                <BackofficeMenuItemTitle icon="action-download">
                                    Download App
                                    <BackofficeMenuItemAction to="/download" icon="code-qr" active={location.pathname.split('/')[1] === 'download'}>
                                        QR Code
                                    </BackofficeMenuItemAction>
                                </BackofficeMenuItemTitle>
                            </BackofficeMenuItem>
                        </BackofficeMenu>
                        <BackofficePageAsideFooter user={this.props.loggedUser._key} role={this.props.loggedUser.ruolo} onClickExit={()=>{this.onExit(history)}} />
                    </BackofficePageAside>
                    <BackofficePageSection>
                        <BackofficePageSectionHeader title={location.pathname} description="">
                            <InputText icon="data-search"/>
                        </BackofficePageSectionHeader>
                        <BackofficePageContent>
                            <Switch>
                                <Route exact path="/utenti" component={Users} />
                                <Route exact path="/utenti-disabilitati" component={UsersDisabled} />
                                <Route exact path="/utenti/:_key" component={User} />
                                <Route exact path="/nuovo-utente" component={CreateUser} />
                                <Route exact path="/ronda" component={Localizzazioni} />
                                <Route exact path="/ronda/:_key" component={Piante} />
                                <Route exact path="/ronda/:_key/:_keysala" component={Ronda} />
                                <Route exact path="/segnalazioni" component={Segnalazioni} />
                                <Route exact path="/segnalazioni/:_key" component={Segnalazione} />
                                {/*<Route exact path="/tutorials-doc" component={TutorialsDoc} />*/}
                                <Route exact path="/tutorials-custode" component={TutorialsCustode} />
                                <Route exact path="/tutorials-conservatore" component={TutorialsConservatore} />
                                <Route exact path="/download" component={DownloadApp} />
                                <Route exact path="/" component={Welcome} />
                            </Switch>
                        </BackofficePageContent>
                    </BackofficePageSection>
                </BackofficePage>
        )
    }
}

Admin.propTypes = {
    logout: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    loggedUser: state.auth.user
})

export default withRouter(connect(mapStateToProps, {logout})(Admin))