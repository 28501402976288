import {
    GET_SALA,
    SALA_LOADING,
    SALA_ERROR,
    REORDER_OPERE_LIST,
    RONDA_SET_OPERE,
    MOVE_OPERA,
    GET_MOVEMENT,
    CLEAR_MOVEMENT,
    UPDATE_MOVEMENT,
    DELETE_MOVEMENT
} from '../actions/types';

import {arrayMove} from 'react-sortable-hoc';

const initialState = {
    sala: [],
    movement: null,
    loading: false
};

export default function sala(state = initialState, action){

    switch(action.type){
        
        case GET_SALA:
            return {
                ...state,
                sala: action.payload,
                loading: false
            }

        case SALA_LOADING:
            return {
                ...state,
                loading: true
            }

        case SALA_ERROR:
            return {
                ...state,
                loading: false
            }
        
        case REORDER_OPERE_LIST:
            return {
                ...state,
                sala: arrayMove(state.sala, action.payload.oldIndex.oldIndex, action.payload.oldIndex.newIndex)
            }

        case RONDA_SET_OPERE:
            return {
                ...state,
                loading: false
            }

        case GET_MOVEMENT:
            return {
                ...state,
                movement: action.payload
            }

        case MOVE_OPERA:
        case UPDATE_MOVEMENT:
            return {
                ...state,
                loading: false
            }

        case CLEAR_MOVEMENT:
        case DELETE_MOVEMENT:
            return {
                ...state,
                movement: null
            }

        default:
            return state;
    }
}

