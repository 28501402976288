import {
    GET_PIANTE,
    PIANTE_LOADING,
    PIANTE_ERROR,
    REORDER_SALE_LIST,
    RONDA_SET_SALE
} from '../actions/types';

import {arrayMove} from 'react-sortable-hoc';

const initialState = {
    piante: [],
    loading: false
};

export default function piant(state = initialState, action){

    switch(action.type){
        
        case GET_PIANTE:
            return {
                ...state,
                piante: action.payload,
                loading: false
            }

        case PIANTE_LOADING:
            return {
                ...state,
                loading: true
            }

        case PIANTE_ERROR:
            return {
                ...state,
                loading: false
            }
        
        case REORDER_SALE_LIST:
            let pianteTmp = state.piante
            let nomePiano = action.payload.newIndex.srcElement.parentElement.parentElement.previousElementSibling.innerText
            let idxPiano = null
            for(let i=0; i<state.piante.length; i++){
                    if(state.piante[i].nome === nomePiano){
                        idxPiano = i
                    }
            }
            pianteTmp[idxPiano].sale = arrayMove(pianteTmp[idxPiano].sale, action.payload.oldIndex.oldIndex, action.payload.oldIndex.newIndex)
            return {
                ...state,
                piante: pianteTmp
            }
        
        case RONDA_SET_SALE:
            return {
                ...state,
                loading: false
            }

        default:
            return state;
    }
}

