// Authentication

export const AUTH_ERROR = "AUTH_ERROR"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const LOGIN_FAIL = "LOGIN_FAIL"


// Errors

export const GET_ERRORS = "GET_ERRORS"
export const CLEAR_ERRORS = "CLEAR_ERRORS"
export const GET_SUCCESS = "GET_SUCCESS"
export const CLEAR_SUCCESS = "CLEAR_SUCCESS"

// Users

export const GET_USERS = 'GET_USERS'
export const GET_USER = 'GET_USER'
export const ADD_USER = 'ADD_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const DELETE_USER = 'DELETE_USER'
export const CLEAR_USER = 'CLEAR_USER'
export const USERS_LOADING = 'USERS_LOADING'
export const USERS_ERROR = 'USERS_ERROR'
export const USER_LOADING = "USER_LOADING"
export const USER_LOADED = "USER_LOADED"

// Segnalazioni

export const GET_SEGNALAZIONI = 'GET_SEGNALAZIONI'
export const GET_SEGNALAZIONE = 'GET_SEGNALAZIONE'
export const UPDATE_SEGNALAZIONE = 'UPDATE_SEGNALAZIONE'
export const CLEAR_SEGNALAZIONE = 'CLEAR_SEGNALAZIONE'
export const SEGNALAZIONI_LOADING = 'SEGNALAZIONI_LOADING'
export const SEGNALAZIONE_LOADING = 'SEGNALAZIONE_LOADING'
export const SEGNALAZIONI_ERROR = 'SEGNALAZIONI_ERROR'
export const COMMENTO_SEGNALAZIONE = 'COMMENTO_SEGNALAZIONE'


// Localizzazioni

export const GET_LOCALIZZAZIONI = 'GET_LOCALIZZAZIONI'
export const LOCALIZZAZIONI_LOADING = 'LOCALIZZAZIONI_LOADING'
export const LOCALIZZAZIONI_ERROR = 'LOCALIZZAZIONI_ERROR'

// Piante

export const GET_PIANTE = 'GET_PIANTE'
export const PIANTE_LOADING = 'PIANTE_LOADING'
export const PIANTE_ERROR = 'PIANTE_ERROR'

// Ronda

export const GET_SALA = 'GET_SALA'
export const SALA_LOADING = 'SALA_LOADING'
export const SALA_ERROR = 'SALA_ERROR'
export const GET_MOVEMENT = 'GET_MOVEMENT'
export const GET_MOVEMENT_FAIL = 'GET_MOVEMENT_FAIL'
export const UPDATE_MOVEMENT = 'UPDATE_MOVEMENT'
export const DELETE_MOVEMENT = 'DELETE_MOVEMENT'
export const CLEAR_MOVEMENT = 'CLEAR_MOVEMENT'
export const MOVE_OPERA = 'MOVE_OPERA'
export const MOVE_OPERA_SUCCESS = 'MOVE_OPERA_SUCCESS'
export const MOVE_OPERA_FAIL = 'MOVE_OPERA_FAIL'
export const REORDER_OPERE_LIST = 'REORDER_OPERE_LIST'
export const REORDER_SALE_LIST = 'REORDER_SALE_LIST'
export const RONDA_SET_SALE = 'RONDA_SET_SALE'
export const RONDA_SET_OPERE = 'RONDA_SET_OPERE'

// Pages

export const PAGE_SWITCH = 'PAGE_SWITCH'






