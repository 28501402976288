import React, { Component } from 'react'
import {Link} from "react-router-dom"
import {SortableContainer, SortableElement} from 'react-sortable-hoc'
import {
  Card,
  H3,
  Tag
} from '@maggioli-design-system/react'
import './SaleSortable.css'

const SortableItem = SortableElement(({value, sortIndex, initialLink}) =>
  <Card className="pianteCard">
    <Link className="linkArgo" to={`${initialLink}/${value._key}`}>
      <Card>
      <Tag icon="dashboard" >
              <H3>{value.nome}</H3>
      </Tag>
      </Card>
    </Link>
  </Card>

)

const SortableList = SortableContainer(({items, initialLink}) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem key={`item-${value._key}`} index={index} value={value} sortIndex={index}  initialLink={initialLink} />
      ))}
    </div>
  )
})

class SaleSortable extends Component {
  
  render() {
    return <SortableList pressDelay={200} helperClass="background-color-status-info-18 box-shadow-soft" initialLink={this.props.initialLink}  items={this.props.data} onSortEnd={this.props.onSortEnd} axis="xy"/>;
  }
}

export default SaleSortable