import React, { Component } from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import Login from './components/Login'
import Admin from './components/Admin'
import './App.css'
class App extends Component {

  static propTypes = {
    isAuthenticated: PropTypes.bool
  }

  render(){
    return this.props.isAuthenticated ? <Admin/> : <Login/>
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(App)