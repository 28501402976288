import React from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom"
import {
    Button,
    Caption,
    Grid,
    Row,
    H1,
    H4,
    H6,
    Hr,
    Icon,
    Image
} from '@maggioli-design-system/react'

import logoArgo from '../../assets/images/logo-argo-icon.svg'

const BackofficePageAside = props =>
  <Grid htmlTag="aside" className="backoffice-page__aside group" template="backoffice-aside">
    <Link className="group-hover:bg-brand-argo-18 rounded-md" to="/">
      <header className="backoffice-page-aside-header">
        {props.logo && <Image src={logoArgo} className="backoffice-page-aside-header__logo"/> }
        <div className="backoffice-page-aside-header__contents">
          <H4 className="backoffice-page-aside-header__title">{props.title}</H4>
          {props.description && <Caption className="backoffice-page-aside-header__sub-title">{props.description}</Caption> }
        </div>
        <Icon name="bank" size="large" className="text-brand-argo-0" />
      </header>
      <Hr className="group-hover:bg-brand-argo-8" spacing="xsmall" />
    </Link>
    {props.children}
  </Grid>

BackofficePageAside.propTypes = {
  logo: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
}

BackofficePageAside.defaultProps = {
  logo: true,
  title: 'Backoffice',
  description: 'Gruppo Maggioli',
}

const BackofficePageAsideFooter = props =>
  <footer className="backoffice-page-aside-footer">
    <div className="backoffice-page-aside-footer__user">
      <Icon name={props.role === 'COORDINATORE' ? 'role-admin' : props.ruolo === 'CONSERVATORE' ? 'role-editor' : 'role-subscriber'} className="backoffice-page-aside-footer__icon"/>
      <Caption className="backoffice-page-aside-footer__email"><strong>{props.user}</strong></Caption>
    </div>
    <Row className="backoffice-page-aside-footer__actions" gutter="xsmall">
      <Button onClick={props.onClickExit} small={true} className="backoffice-page-aside-footer__action background-color-status-error-08">Esci</Button>
    </Row>
  </footer>

BackofficePageAsideFooter.propTypes = {
  user: PropTypes.string,
  role: PropTypes.string,
  onClickExit: PropTypes.func,
}

BackofficePageAsideFooter.defaultProps = {
  user: 'username',
  role: 'custode',
  onClickExit: () => {},
}

function setTitle(loc){
  switch(loc.split("/")[1]){
    case "utenti":
      return "Utenti"
    case "nuovo-utente":
      return "Nuovo Utente"
    case "ronda":
      return "Ronda"
    case "segnalazioni":
      return "Segnalazioni"
    case "tutorials-custode":
    case "tutorials-conservatore":
      return "Tutorials"
    case "tutorials-doc":
      return "Documentazione"
    case "download":
      return "Download"
    default:
      return "Amministrazione"
  }
}

const BackofficePageSectionHeader = props =>
  <Grid htmlTag="header" className="backoffice-page-content-header" template="content-header">
    <div className="backoffice-page-content-header__info">
      <H1>{setTitle(props.title)}</H1>
      <H6 className="color-adjust-tone-08">{props.description}</H6>
    </div>
  </Grid>

BackofficePageSectionHeader.propTypes = {
  title: PropTypes.any,
  description: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  visible: PropTypes.bool,
}

BackofficePageSectionHeader.defaultProps = {
  title: 'Modello',
  description: 'Description',
  onChange: () => {},
  value: '',
  visible: true,
}

const BackofficePageSection = props =>
  <div className="backoffice-page__section">
    {props.children}
  </div>

const BackofficePageContent = props =>
  <div className="backoffice-page__content">
    {props.children}
  </div>

const BackofficePage = props =>
  <Grid className={`backoffice-page ${props.isOpened ? 'backoffice-page--menu-opened' : ''}`} gutter="none" template="backoffice">
    <div className="backoffice-page__close" onClick={props.onClick}>
      <Icon name={props.isOpened ? 'action-close' : 'menu-main'}/>
    </div>
    {props.children}
  </Grid>

BackofficePage.propTypes = {
  header: PropTypes.any,
  isOpened: PropTypes.bool,
  menu: PropTypes.any,
  onClick: PropTypes.func,
  footer: PropTypes.any,
  title: PropTypes.string,
}

BackofficePage.defaultProps = {
  isOpened: false,
  onClick: () => {},
  title: 'Maggioli',
}

export default BackofficePage
export {
  BackofficePageAside,
  BackofficePageAsideFooter,
  BackofficePageContent,
  BackofficePageSection,
  BackofficePageSectionHeader,
}
