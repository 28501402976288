import axios from 'axios';
import {url} from '../config/Config'
import {
    GET_SEGNALAZIONI, 
    SEGNALAZIONI_LOADING, 
    SEGNALAZIONE_LOADING, 
    SEGNALAZIONI_ERROR, 
    GET_SEGNALAZIONE, 
    COMMENTO_SEGNALAZIONE,
    CLEAR_SEGNALAZIONE
} from './types'
import {tokenConfig} from './config';
import { returnErrors } from './errorActions';

export const getSegnalazioni = (filter = '') => (dispatch, getState) => {
    dispatch(setSegnalazioniLoading());
    axios
        .get( filter !== '' ? `${url}/api/v1/segnalazioni?filter=${filter}` : `${url}/api/v1/segnalazioni`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_SEGNALAZIONI,
                payload: res.data.data
            })
        })
        .catch(err => {
            console.log(err)
            dispatch(returnErrors(err.response.data, err.response.status, 'SEGNALAZIONI_ERROR'));
            dispatch({
                type: SEGNALAZIONI_ERROR
            })
        })
}

export const getSegnalazione = (_key) => (dispatch, getState) => {
    dispatch(setSegnalazioneLoading());
    axios
        .get(`${url}/api/v1/segnalazioni/${_key}`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_SEGNALAZIONE,
                payload: res.data.data[0]
            })
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status, 'SEGNALAZIONI_ERROR'));
            dispatch({
                type: SEGNALAZIONI_ERROR
            })
        })
}

export const commentSegnalazione = (_key, commento) => (dispatch, getState) => {
    dispatch(setSegnalazioneLoading());
    axios
        .post(`${url}/api/v1/commenti/${_key}`, commento, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: COMMENTO_SEGNALAZIONE,
                payload: res.data.data
            })
            dispatch(getSegnalazione(_key))
        })
        .catch(err => {
            console.log(err)
            dispatch(returnErrors(err.response.data, err.response.status, 'SEGNALAZIONI_ERROR'));
            dispatch({
                type: SEGNALAZIONI_ERROR
            })
        })
}

export const setSegnalazioniLoading = () => {
    return {
        type: SEGNALAZIONI_LOADING
    }
}

export const setSegnalazioneLoading = () => {
    return {
        type: SEGNALAZIONE_LOADING
    }
}

export const clearSegnalazione = () => {
    return {
        type: CLEAR_SEGNALAZIONE
    }
}