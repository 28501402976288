module.exports = {
    parseData : data => {
        
        data = data.toString()
        let year = data.substring(0, 4)
        let month = data.substring(4, 6)
        let day = data.substring(6,8)
        let hour = data.substring(8, 10)
        let minute = data.substring(10, 12)
        let second = data.substring(12,14)

        return {
            data : day + " / " + month + " / " + year,
            ora: hour + " : " + minute + " : " + second
        } 

    },

    parseDataCalendar : data => {
        data = data.toString()
        let year = data.substring(0, 4)
        let month = data.substring(4, 6)
        let day = data.substring(6,8)
        // let hour = data.substring(8, 10)
        // let minute = data.substring(10, 12)
        // let second = data.substring(12,14)

        return year + "," + month + "," + day //+ "," + hour + "," + minute + "," + second
    }
}