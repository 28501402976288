import React, { Component } from 'react'
import {
    Grid,
    H4,
    Image
} from '@maggioli-design-system/react'
import QR from '../assets/images/Argo-1.1.5.png'

export default class DownloadApp extends Component {
    render() {
        return (
            <Grid columns="3">
                <Image src={QR}></Image>
                <H4>Per scaricare l'applicazione inquadrare il QR Code con il tablet e procedere con l'installazione</H4>
            </Grid>
        )
    }
}
