import React from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";

import {
    Icon,
    Detail,
    Row,
    H4
} from '@maggioli-design-system/react'

const BackofficeMenuItemTitle = props =>
  <Row align="top">
    <H4 htmlTag="div">
      <Icon name={props.icon} className="backoffice-menu__icon text-brand-argo-8"/>
    </H4>
    <H4>{props.children}</H4>
  </Row>

BackofficeMenuItemTitle.propTypes = {
  icon: PropTypes.string,
}

BackofficeMenuItemTitle.defaultProps = {
  icon: 'save',
}

const BackofficeMenuItemAction = props =>
<Link to={props.to} onClick={props.onClick} className={`backoffice-menu-action text-secondary text-secondary--detail ${props.active ? 'backoffice-menu-action--active mr-0' : ''}`}>
  <Icon name={props.icon} className="backoffice-menu-action__icon text-brand-argo-10"/>
  <Detail className="backoffice-menu-action__text">
    {props.children}
  </Detail>
</Link>

BackofficeMenuItemAction.propTypes = {
    active: PropTypes.bool,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    to: PropTypes.string
}
  
BackofficeMenuItemAction.defaultProps = {
    active: false,
    icon: 'save',
    onClick: () => {},
    to: '/'
}

export {
  BackofficeMenuItemAction,
  BackofficeMenuItemTitle
}