import React, {Component} from 'react'
import {connect} from 'react-redux'

import LoaderGif from '../assets/images/loader.gif'

class Loader extends Component {
    render() {

        if(this.props.load_users ||
           this.props.load_user ||
           this.props.load_segnalazioni ||
           this.props.load_segnalazione ||
           this.props.load_localizzazioni ||
           this.props.load_piante ||
           this.props.load_sala
        ) {
            return (
                <div className="loader-container">
                    <div className="loader">
                        <img alt="Loading..." src={LoaderGif}/>
                    </div>
                </div>
            )   
        }

        return null
    }
}

const mapStateToProps = state => ({
    load_users: state.users.loading,
    load_user: state.user.loading,
    load_segnalazioni: state.segnalazioni.loading,
    load_segnalazione: state.segnalazione.loading,
    load_localizzazioni: state.localizzazioni.loading,
    load_piante: state.piante.loading,
    load_sala: state.sala.loading
})

export default connect(mapStateToProps)(Loader);