import { 
    GET_SEGNALAZIONE,
    UPDATE_SEGNALAZIONE,
    COMMENTO_SEGNALAZIONE,
    SEGNALAZIONE_LOADING,
    SEGNALAZIONI_ERROR,
    CLEAR_SEGNALAZIONE
} from '../actions/types';

const initialState = {
    segnalazione: {},
    loading: false
};

export default function segn(state = initialState, action){

    switch(action.type){
        
        case GET_SEGNALAZIONE:
            return {
                ...state,
                segnalazione: action.payload,
                loading: false
            }
        
        case UPDATE_SEGNALAZIONE:
            return {
                ...state,
                loading: false
            }

        case COMMENTO_SEGNALAZIONE:
            return {
                ...state,
                loading: false
            }

        case SEGNALAZIONE_LOADING:
            return {
                ...state,
                loading: true
            }

        case SEGNALAZIONI_ERROR:
            return {
                ...state,
                loading: false
            }

        case CLEAR_SEGNALAZIONE:
            return {
                ...state,
                segnalazione: null
            }

        default:
            return state;
    }
}

