import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Link} from "react-router-dom"
import { getPiante, orderSaleList } from '../../actions/pianteActions'
import {clearErrors} from '../../actions/errorActions';
import {clearSuccess} from '../../actions/successActions';

import Loader from '../Loader'
import Accordion, {AccordionItem} from '../Custom/Accordion'
import SaleSortable from '../Custom/SaleSortable'
import {
    Banner,
    Tag,
    Hr
} from '@maggioli-design-system/react'

class Piante extends Component {

    state = {
        message: null,
        success: null
    }

    componentDidMount(){
        this.props.getPiante(this.props.match.params._key)
    }

    componentDidUpdate(prevProps) {
        const {error} = this.props;
        const {success} = this.props;
        if(error !== prevProps.error){
            if(error.id === 'RONDA_ERROR'){
                this.setState({message: error.message.message})
            } else {
                this.setState({message: null})
            }
        }
        if(success !== prevProps.success){

            if(success.id === 'RONDA_UPDATE_SUCCESS'){
                this.setState({success: success.message})
            } else {
                this.setState({success: null})
            }
        }
    }

    renderMessage = () => {
        if(this.state.message){
            return <Banner status="error">{this.state.message}</Banner>
        }
        else if(this.state.success){
            return <Banner status="success">{this.state.success}</Banner>
        }
    }

    render() {
        const {piante} = this.props.piante
        const {localizzazioni} = this.props.localizzazioni
        const {_key} = this.props.match.params
        const loc = localizzazioni.filter(l => l._key === _key)[0].nome

        if(piante){
            return (
                <div>
                    <Loader/>
                    {this.renderMessage()}
                    <Link to="/ronda" ><Tag chip className="bg-brand-argo-16 hover:bg-brand-argo-14">{loc}</Tag></Link>
                    <Hr />
                    <Accordion>
                        {piante.map(p =>(
                            <AccordionItem key={p._key} title={p.nome}>
                                <SaleSortable data={p.sale} initialLink={`/ronda/${this.props.match.params._key}`} 
                                              onSortEnd={this.props.orderSaleList} />
                            </AccordionItem>
                        ))}
                    </Accordion>
                </div>
            )
        }else{
            return (<h1>Nessuna Pianta</h1>)
        }
    }
}

Piante.propTypes = {
    getPiante: PropTypes.func.isRequired,
    orderSaleList: PropTypes.func.isRequired,
    piante: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    piante: state.piante,
    localizzazioni: state.localizzazioni
});

export default connect(mapStateToProps, {getPiante, orderSaleList, clearErrors, clearSuccess})(Piante)