import { 
    GET_SEGNALAZIONI,
    SEGNALAZIONI_LOADING,
    SEGNALAZIONI_ERROR
} from '../actions/types';

const initialState = {
    segnalazioni: [],
    loading: false
};

export default function segns(state = initialState, action){

    switch(action.type){

        case GET_SEGNALAZIONI:
            return {
                ...state,
                segnalazioni: action.payload,
                loading: false
            }

        case SEGNALAZIONI_LOADING:
            return {
                ...state,
                loading: true
            }

        case SEGNALAZIONI_ERROR:
            return {
                ...state,
                loading: false
            }

        default:
            return state;
    }
}

