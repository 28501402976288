import { 
    GET_USERS,
    DELETE_USER,
    USERS_LOADING, 
    USERS_ERROR 
} from '../actions/types';

const initialState = {
    users: [],
    loading: false
};

export default function usrs(state = initialState, action){

    switch(action.type){

        case GET_USERS:
            return {
                ...state,
                users: action.payload,
                loading: false
            }
        
        case DELETE_USER:
            return {
                ...state,
                users: state.users.filter(user => user._key !== action.payload)
            }

        case USERS_LOADING:
            return {
                ...state,
                loading: true
            }

        case USERS_ERROR:
            return {
                ...state,
                loading: false
            }

        default:
            return state;
    }
}

