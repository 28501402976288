import { 
    GET_LOCALIZZAZIONI
} from '../actions/types';

const initialState = {
    localizzazioni: [],
    loading: false
};

export default function loc(state = initialState, action){

    switch(action.type){

        case GET_LOCALIZZAZIONI:
            return {
                ...state,
                localizzazioni: action.payload
            }

        default:
            return state;
    }
}

