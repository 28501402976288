import axios from 'axios';
import {url} from '../config/Config';
import { returnErrors } from './errorActions';
import {tokenConfig} from './config'
import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS
} from './types';

export const login = user => dispatch => {

    const config = {
        headers: {
            "Content-type": "application/json"
        }
    }
    
    axios.post(`${url}/api/v1/auth/login`, user, config)
    .then(res => 
        dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data.logged.data
    }))
    .catch(err => {
        console.log(err)
        dispatch(returnErrors(err.response.data, err.response.status, 'LOGIN_FAIL'));
        dispatch({
            type: LOGIN_FAIL
        })
    })
}

export const logout = () => (dispatch, getState) =>{
    axios.get(`${url}/api/v1/auth/logout`, tokenConfig(getState))
    .then(res => {
        localStorage.clear()
        dispatch({
            type: LOGOUT_SUCCESS
        })
        }
    )
    .catch(err => {
        console.log(err)
    })
}