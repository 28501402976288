import axios from 'axios';
import {url} from '../config/Config';
import {GET_PIANTE, PIANTE_LOADING, PIANTE_ERROR, REORDER_SALE_LIST, RONDA_SET_SALE} from './types'
import {tokenConfig} from './config';
import { returnSuccess } from './successActions';
import { returnErrors } from './errorActions';

export const getPiante = _key => (dispatch, getState) => {
    dispatch(setPianteLoading());
    axios
        .get(`${url}/api/v1/sale?localizzazione=${_key}`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_PIANTE,
                payload: res.data.data
            })
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status, 'PIANTE_ERROR'));
            dispatch({
                type: PIANTE_ERROR
            })
        })
}

export const rondaSetSale = piante => (dispatch, getState) => {
    axios
        .post(`${url}/api/v1/ronda/piante`, piante, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: RONDA_SET_SALE
            })
            dispatch(returnSuccess(res.data.message, res.status, 'RONDA_UPDATE_SUCCESS'))
        })
        .catch(err => {
            console.log(err)
            dispatch(returnErrors(err.response.data, err.response.status, 'RONDA_ERROR'));
            dispatch({
                type: PIANTE_ERROR
            })
        })
}

export const orderSaleList = (oldIndex, newIndex) => (dispatch, getState) => {
    dispatch(setPianteLoading())
    dispatch({
        type: REORDER_SALE_LIST,
        payload: {oldIndex, newIndex},
    })
    let newOrder = []
    newIndex.target.childNodes.forEach(c => {
        let href = c.firstChild.href
        href = href.substr(href.lastIndexOf("/") + 1, href.length)
        newOrder.push(href)
    })
    dispatch(rondaSetSale(newOrder))
}

export const setPianteLoading = () => {
    return {
        type: PIANTE_LOADING
    }
}