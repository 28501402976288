import axios from 'axios';
import {url} from '../config/Config';
import { GET_USERS, GET_USER, ADD_USER, DELETE_USER, USERS_LOADING, USER_LOADING, USERS_ERROR, UPDATE_USER, CLEAR_USER } from './types';
import {tokenConfig} from './config';
import { returnSuccess } from './successActions';
import { returnErrors } from './errorActions';

export const getUsers = (filter = '') => (dispatch, getState) => {
    dispatch(setUsersLoading());
    axios
        .get(`${url}/api/v1/utenti?filter=${filter}`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_USERS,
                payload: res.data.data
            })
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status, 'USERS_ERROR'));
            dispatch({
                type: USERS_ERROR
            })
        })
}

export const getUser = (_key) => (dispatch, getState) => {
    dispatch(setUserLoading());
    axios
        .get(`${url}/api/v1/utenti/${_key}`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_USER,
                payload: res.data.data
            })
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status, 'USERS_ERROR'));
            dispatch({
                type: USERS_ERROR
            })
        })
}

export const createUser = user => (dispatch, getState) => {
   dispatch(setUserLoading());
   axios
    .post(`${url}/api/v1/utenti`, user, tokenConfig(getState))
    .then(res => {
        console.log(res)
        dispatch({type: ADD_USER})
        dispatch(returnSuccess(res.data.message, res.status, 'USER_CREATE_SUCCESS'))
    })
    .catch(err => {
        console.log(err)
        dispatch(returnErrors(err.response.data, err.response.status, 'USERS_ERROR'));
        dispatch({
            type: USERS_ERROR
        })
    })
}

export const updateUser = (user) => (dispatch, getState) => {
    dispatch(setUserLoading());
    axios
        .put(`${url}/api/v1/utenti/${user._key}`, user, tokenConfig(getState))
        .then(res => {
            dispatch({type: UPDATE_USER})
            dispatch(returnSuccess(res.data.message, res.status, 'USER_UPDATE_SUCCESS'))
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status, 'USERS_ERROR'));
            dispatch({
                type: USERS_ERROR
            })
        })
}

export const deleteUser = _key => (dispatch, getState) => {
    axios
        .delete(`${url}/api/v1/utenti/${_key}`, tokenConfig(getState))
        .then(res => {
            console.log(res)
            dispatch({
                type: DELETE_USER,
                payload: _key
            })
            dispatch(returnSuccess(res.data.message, res.status, 'USER_DELETE_SUCCESS'))
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status, 'USERS_ERROR'));
            dispatch({
                type: USERS_ERROR
            })
        })
}

export const setUsersLoading = () => {
    return {
        type: USERS_LOADING
    }
}

export const setUserLoading = () => {
    return {
        type: USER_LOADING
    }
}

export const clearUser = () => {
    return {
        type: CLEAR_USER
    }
}
