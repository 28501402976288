import axios from 'axios';
import {url} from '../config/Config';
import {GET_LOCALIZZAZIONI, LOCALIZZAZIONI_LOADING, LOCALIZZAZIONI_ERROR} from './types'
import {tokenConfig} from './config';
import { returnErrors } from './errorActions';

export const getLocalizzazioni = () => (dispatch, getState) => {
    dispatch(setLocalizzazioniLoading());
    axios
        .get(`${url}/api/v1/localizzazioni`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_LOCALIZZAZIONI,
                payload: res.data.data
            })
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status, 'PIANTE_ERROR'));
            dispatch({
                type: LOCALIZZAZIONI_ERROR
            })
        })
}

export const setLocalizzazioniLoading = () => {
    return {
        type: LOCALIZZAZIONI_LOADING
    }
}