import React, { Component } from 'react'
import {
    H2,
    Tag,
    Grid,
    Card,
    H4
} from '@maggioli-design-system/react'

export class TutorialsDoc extends Component {
    render() {
        return (
            <div>Documentazione PDF</div>
        )
    }
}

export class TutorialsCustode extends Component {
    render() {
        return (
            <Grid>
                <Card>
                <Tag iconClassName="text-brand-argo-8" className="text-brand-argo-5 bg-brand-argo-20" size="xlarge" chip icon="role-subscriber"><H2>Custode - VideoTutorial</H2></Tag>
                <Grid columns="2">
                    <div>
                        <iframe src="https://player.vimeo.com/video/545054405" width="640" height="360" title="Segnalazione Opera" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                        <H4 className="p-5">· Segnalazione Opera</H4>
                    </div>
                    <div>
                        <iframe src="https://player.vimeo.com/video/545054525" width="640" height="360" title="Segnalazione Sala" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                        <H4 className="p-5">· Segnalazione Sala</H4>
                    </div>
                    <div>
                        <iframe src="https://player.vimeo.com/video/545054303" width="640" height="360" title="Convalida" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                        <H4 className="p-5">· Convalida</H4>
                    </div>
                    <div>
                        <iframe src="https://player.vimeo.com/video/545054166" width="640" height="360" title="Consultazione Segnalazioni" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                        <H4 className="p-5">· Consultazione Segnalazioni</H4>
                    </div>
                </Grid>
                </Card>
            </Grid>
        )
    }
}

export class TutorialsConservatore extends Component {
    render() {
        return (
            <Grid>
                <Card>
                <Tag iconClassName="text-brand-argo-8" className="text-brand-argo-5 bg-brand-argo-20" size="xlarge" chip icon="role-editor"><H2>Conservatore - VideoTutorial</H2></Tag>
                <Grid columns="2">
                    <div>
                        <iframe src="https://player.vimeo.com/video/545063199" width="640" height="360" title="Presa in carico - Segnalazione Sala" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                        <H4 className="p-5">· Presa in carico - Segnalazione Sala</H4>
                    </div>
                    <div>
                        <iframe src="https://player.vimeo.com/video/545063153" width="640" height="360" title="Presa in carico - Segnalazione Opera" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                        <H4 className="p-5">· Presa in carico - Segnalazione Opera</H4>
                    </div>
                    <div>
                        <iframe src="https://player.vimeo.com/video/545063142" width="640" height="360" title="Chiusura - Segnalazione Sala" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                        <H4 className="p-5">· Chiusura - Segnalazione Sala</H4>
                    </div>
                    <div>
                        <iframe src="https://player.vimeo.com/video/545063008" width="640" height="360" title="Chiusura - Segnalazione Opera" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                        <H4 className="p-5">· Chiusura - Segnalazione Opera</H4>
                    </div>
                </Grid>
                </Card>
            </Grid>
        )
    }
}

