import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from "react-router-dom";
import {store, persistor} from './store'
import {PersistGate} from 'redux-persist/integration/react'

// import '@maggioli-design-system/icons/mgg-icons.css'
import '@maggioli-design-system/icons/dist/mgg-icons.css'
import '@maggioli-design-system/styles/dist/css/styles.min.css'
import '@maggioli-design-system/design-tokens/dist/css/vars.css'
import 'typeface-karla'
import 'typeface-roboto'
import './assets/css/tailwind.css'

import App from './App.jsx'

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </Router>,
  document.getElementById('root')
)
