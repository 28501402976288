import React, { Component } from 'react'
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {login} from '../actions/authActions';
import {clearErrors} from '../actions/errorActions';

import {
    BackofficePageLogin,
    BackofficePageLoginHeader,
    BackofficePageLoginBody,
    Button,
    InputText,
    InputPassword,
    Banner
} from '@maggioli-design-system/react'
import logo from '@maggioli-design-system/identity/dist/argo/logo-argo-icon.svg'

class Login extends Component {

    state = {
        _key: '',
        password: "",
        message: null
    }

    static propTypes = {
        isAuthenticated: PropTypes.bool,
        error: PropTypes.object.isRequired,
        login: PropTypes.func.isRequired,
        clearErrors: PropTypes.func.isRequired
    }

    componentDidUpdate(prevProps) {
        const {error} = this.props

        if(error !== prevProps.error){
            if(error.id === 'LOGIN_FAIL'){
                this.setState({message: error.message.message})
            } else {
                this.setState({message: null})
            }
        }
    }

    onChange = e => {
        if(this.state.message)
            this.props.clearErrors()
        this.setState({ [e.target.name]: e.target.value })
    }

    onSubmit = () => {
        this.props.clearErrors()
        const user = {
            _key: this.state._key,
            password: this.state.password
        }
        
        this.props.login(user)
    }
    
    render() {
        return (
            <BackofficePageLogin>
                <BackofficePageLoginBody>
                <BackofficePageLoginHeader logo={logo} title="Argo" description="Accedi al servizio"/>
                <InputText iconClassName="text-brand-argo-10" onChange={this.onChange} name="_key" placeholder="Username" icon="user" />
                <InputPassword iconClassName="text-brand-argo-10" onChange={this.onChange} name="password" placeholder="Password" />
                {this.state.message ? <Banner status="error">{this.state.message}</Banner> : null}
                <Button className="bg-brand-argo-10" onClick={this.onSubmit}>Accedi</Button>
                </BackofficePageLoginBody>
            </BackofficePageLogin>
        )
    }
}

const mapStateToProps = state => ({
    error: state.error,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, {login, clearErrors})(Login)