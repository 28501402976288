export const tokenConfig = (getState, body) => {

    const token = getState().auth.token

    const config = {
        headers: {
            "Content-type": "application/json"
        }
    }

    if(token){
        config.headers['Authorization'] = token;
    }

    return config;
}