import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { createUser } from '../actions/userActions'
import {clearErrors} from '../actions/errorActions'
import {clearSuccess} from '../actions/successActions'

import Loader from './Loader'
import {
    Grid,
    Button,
    BackofficeInputText,
    BackofficeInputEmail,
    BackofficeInputPassword,
    BackofficeSelect,
    BackofficeSelectOption,
    Banner
} from '@maggioli-design-system/react'

class CreateUser extends Component {

    state= {
        message: null,
        success: null
    }

    componentDidUpdate(prevProps) {
        const {error} = this.props
        const {success} = this.props
        const {history} = this.props
        if(error !== prevProps.error){
            if(error.id === 'USERS_ERROR'){
                this.setState({message: error.message.message})
            } else {
                this.setState({message: null})
            }
        }
        if(success !== prevProps.success){
            if(success.id === 'USER_CREATE_SUCCESS'){
                this.setState({success: success.message})
                setTimeout(function () {
                    history.go(-1)
                }, 2500);
            } else {
                this.setState({success: null})
            }
        }
    }

    onChange = e => {
        if(this.state.message)
            this.props.clearErrors()
        if(this.state.success)
            this.props.clearSuccess()
        this.setState({ [e.target.name]: e.target.value })
    }

    onChangeRole = (event) => {
        if (this.state.message) {
            this.props.clearErrors();
        }
        this.setState({ ruolo: event.target.value })
    }

    submitUser = () => {
        this.props.clearErrors();
        const user = {
            _key: this.state._key,
            info: {
                nome: this.state.nome,
                cognome: this.state.cognome,
                email: this.state.email,
                telefono: this.state.telefono
            },
            ruolo: this.state.ruolo,
            password: this.state.password,
            password2: this.state.password2,
            abilitato: true
        }
        this.props.createUser(user);
    }

    renderMessage = () => {
        if (this.state.message) {
            return <Banner status="error">{this.state.message}</Banner>
        }
        else if (this.state.success) {
            return <Banner status="success">{this.state.success}</Banner>
        } 
    }

    render() {
        return (
            <Grid>
                <Loader/>
                <Grid columns="2">
                    <BackofficeInputText iconClassName="text-brand-argo-10" name="_key" label="Username" icon="user" onChange={this.onChange} placeholder="Inserisci lo username" />
                    <BackofficeInputText iconClassName="text-brand-argo-10" name="nome" label="Nome" icon="user" onChange={this.onChange} placeholder="Inserisci il nome" />
                    <BackofficeInputText iconClassName="text-brand-argo-10" name="cognome" label="Cognome" icon="user" onChange={this.onChange} placeholder="Inserisci il cognome" />
                    <BackofficeInputEmail iconClassName="text-brand-argo-10" name="email" label="Email" onChange={this.onChange} placeholder="Inserisci la mail" />
                    <BackofficeInputText iconClassName="text-brand-argo-10" name="telefono" label="Telefono" icon="action-phone" onChange={this.onChange} placeholder="Inserisci il telefono" />
                    <BackofficeSelect iconClassName="text-brand-argo-10" icon={this.state.ruolo === 'COORDINATORE' ? 'role-admin'
                                        : this.state.ruolo === 'CONSERVATORE' ? 'role-editor' 
                                        : this.state.ruolo === 'CUSTODE' ? 'role-subscriber' 
                                        : 'user-privileges'} 
                                    name="ruolo" label="Ruolo" onChange={this.onChangeRole} placeholder="Ruolo">
                        <BackofficeSelectOption key={''} value={''}>
                            Scegli un ruolo...
                        </BackofficeSelectOption>
                        <BackofficeSelectOption value="CUSTODE">Custode</BackofficeSelectOption>
                        <BackofficeSelectOption value="CONSERVATORE">Conservatore</BackofficeSelectOption>
                        <BackofficeSelectOption value="COORDINATORE">Coordinatore</BackofficeSelectOption>
                    </BackofficeSelect>
                    <BackofficeInputPassword iconClassName="text-brand-argo-10" name="password" label="Password" icon="security-password" onChange={this.onChange} placeholder="Inserisci la password" />
                    <BackofficeInputPassword iconClassName="text-brand-argo-10" name="password2" label="Ripeti Password" icon="security-password" onChange={this.onChange} placeholder="Ripeti la password" />
                </Grid>
                {this.renderMessage()}
                <Grid>
                    <Button variant="success" onClick={this.submitUser}>
                        Salva
                    </Button>
                </Grid>
            </Grid>
        )
        
    }
}

CreateUser.propTypes = {
    createUser: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    clearSuccess: PropTypes.func.isRequired,
    success: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    success: state.success,
    error: state.error
});

export default connect(mapStateToProps, {createUser, clearErrors, clearSuccess})(CreateUser)
