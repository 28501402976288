import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { getUser, updateUser, deleteUser, clearUser } from '../actions/userActions'
import {clearErrors} from '../actions/errorActions'
import {clearSuccess} from '../actions/successActions'

import Loader from './Loader'
import {
    H2,
    H5,
    Hr,
    Grid,
    Button,
    Tag,
    Row,
    Switch,
    BackofficeInputText,
    BackofficeInputEmail,
    BackofficeInputPassword,
    BackofficeSelect,
    BackofficeSelectOption,
    Banner
} from '@maggioli-design-system/react'

class User extends Component {

    state= {
        message: null,
        success: null
    }
    
    componentDidMount(){
        this.props.getUser(this.props.match.params._key)
    }
    componentWillUnmount(){
        this.props.clearUser()
    }

    componentDidUpdate(prevProps) {
        const {error} = this.props
        const {success} = this.props
        const {history} = this.props
        const {user} = this.props

        if(user !== prevProps.user){
            if(user.user && user.user._key){
                this.setState({abilitato: user.user.abilitato})
            }
        }
        if(error !== prevProps.error){
            if(error.id === 'USERS_ERROR'){
                this.setState({message: error.message.message})
            } else {
                this.setState({message: null})
            }
        }
        if(success !== prevProps.success){

            if(success.id === 'USER_UPDATE_SUCCESS'){
                this.setState({success: success.message})
                setTimeout(function () {
                    history.go(-1)
                }, 2500);
            } else {
                this.setState({success: null})
            }
        }
    }

    onChange = e => {
        if(!this.state._key){
            this.setState({_key: this.props.user.user._key})
        }
        if(this.state.message)
            this.props.clearErrors()
        if(this.state.success)
            this.props.clearSuccess()
        this.setState({ [e.target.name]: e.target.value })
    }

    onChangeRole = e => {
        if(!this.state._key){
            this.setState({_key: this.props.user.user._key})
        }
        if (this.state.message) {
            this.props.clearErrors();
        }
        this.setState({ ruolo: e.target.value })
    }

    onChangeAbilitato = e => {
        if(!this.state._key){
            this.setState({_key: this.props.user.user._key})
        }
        if(this.state.message)
            this.props.clearErrors()
        if(this.state.success)
            this.props.clearSuccess()
        this.setState({ "abilitato": e })
    }

    saveUser = () => {
        this.props.clearErrors()
        const user = {
            _key: this.state._key,
            info: {
                nome: this.state.nome,
                cognome: this.state.cognome,
                email: this.state.email,
                telefono: this.state.telefono
            },
            ruolo: this.state.ruolo,
            abilitato : this.state.abilitato
        };
        
        if(this.state.password && this.state.password2){
            user.password = this.state.password
            user.password2 = this.state.password2
        }
        
        this.props.updateUser(user);
    }

    renderMessage = () => {
        if (this.state.message) {
            return <Banner status="error">{this.state.message}</Banner>
        }
        else if (this.state.success) {
            return <Banner status="success">{this.state.success}</Banner>
        } 
    }

    render() {
        const {user} = this.props.user
        if(user && user.info){
            return (
                <Grid>
                    <Row lastChild="to-right">
                        <>
                            <H2>{user._key}</H2>
                            <Tag icon={user.ruolo === 'COORDINATORE' ? 'role-admin'
                                                    : user.ruolo === 'CUSTODE' ? 'role-subscriber' 
                                                    : 'role-editor'}>
                                <H5 className="text-adjust-tone-06">{user.ruolo}</H5>
                            </Tag>
                        </>
                        <Switch boxClassName="bg-brand-argo-10" name="abilitato" isChecked={this.state.abilitato} onChange={this.onChangeAbilitato}>
                            {this.state.abilitato ? "Abilitato" : "Disabilitato"}
                        </Switch>
                    </Row>
                    <Hr spacing="xxsmall" className="bg-brand-argo-10" />
                    <Grid columns="2">
                        <BackofficeInputText iconClassName="text-brand-argo-10" value={user.info.nome} name="nome" label="Nome" icon="user" onChange={this.onChange} placeholder="Inserisci il nome" />
                        <BackofficeInputText iconClassName="text-brand-argo-10" value={user.info.cognome} name="cognome" label="Cognome" icon="user" onChange={this.onChange} placeholder="Inserisci il cognome" />
                        <BackofficeInputEmail iconClassName="text-brand-argo-10" value={user.info.email} name="email" label="Email" onChange={this.onChange} placeholder="Inserisci la mail" />
                        <BackofficeInputText iconClassName="text-brand-argo-10" value={user.info.telefono} name="telefono" label="Telefono" icon="action-phone" onChange={this.onChange} placeholder="Inserisci il telefono" />
                    </Grid>
                    <Grid columns="3">
                        <BackofficeSelect iconClassName="text-brand-argo-10" icon={this.state.ruolo === 'COORDINATORE' ? 'role-admin'
                                            : this.state.ruolo === 'CONSERVATORE' ? 'role-editor' 
                                            : this.state.ruolo === 'CUSTODE' ? 'role-subscriber' 
                                            : 'user-privileges'} 
                                        value={this.state.ruolo} name="ruolo" label="Ruolo" onChange={this.onChangeRole} placeholder="Ruolo">
                            <BackofficeSelectOption key={''} value={''}>
                                Scegli un ruolo...
                            </BackofficeSelectOption>
                            <BackofficeSelectOption value="CUSTODE">Custode</BackofficeSelectOption>
                            <BackofficeSelectOption value="CONSERVATORE">Conservatore</BackofficeSelectOption>
                            <BackofficeSelectOption value="COORDINATORE">Coordinatore</BackofficeSelectOption>
                        </BackofficeSelect>
                        <BackofficeInputPassword iconClassName="text-brand-argo-10" name="password" label="Password" icon="security-password" onChange={this.onChange} placeholder="Inserisci la password" />
                        <BackofficeInputPassword iconClassName="text-brand-argo-10" name="password2" label="Ripeti Password" icon="security-password" onChange={this.onChange} placeholder="Ripeti la password" />
                    </Grid>
                    {this.renderMessage()}
                    <Grid>
                        <Button variant="success" onClick={this.saveUser} disabled={!this.state._key}>
                            Salva
                        </Button>
                    </Grid>
                </Grid>
            )
        } else {
            return <Loader/>
        }
        
    }
}

User.propTypes = {
    getUser: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    updateUser: PropTypes.func.isRequired,
    error: PropTypes.object.isRequired,
    success: PropTypes.object.isRequired,
    clearErrors: PropTypes.func.isRequired,
    clearSuccess: PropTypes.func.isRequired,
    clearUser: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    user: state.user,
    error: state.error,
    success: state.success
});

export default connect(mapStateToProps, {getUser, updateUser, deleteUser, clearErrors, clearSuccess, clearUser})(User)
