import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Link} from "react-router-dom";
import { getUsers, deleteUser } from '../actions/userActions'
import Loader from './Loader'
import {
    Table,
    TableHeader,
    TableHeaderCell,
    TableBody,
    TableRow,
    TableCell,
    Tag,
    Detail,
    Grid,
    Button
} from '@maggioli-design-system/react'

class UsersDisabled extends Component {

    componentDidMount() {
        this.props.getUsers('disabilitati')
    }

    render() {
        let {users} = this.props.users
        return (
            <div>
                <Loader/>
                <Table interactive={true}>
                    <TableHeader>
                        <TableHeaderCell>Username</TableHeaderCell>
                        <TableHeaderCell>Nome e Cognome</TableHeaderCell>
                        <TableHeaderCell>Email</TableHeaderCell>
                        <TableHeaderCell>Telefono</TableHeaderCell>
                        <TableHeaderCell>Ruolo</TableHeaderCell>
                    </TableHeader>
                    <TableBody>
                        {users.map(user =>(
                            <TableRow key={user._key}>
                                <TableCell>
                                    {user._key === this.props.loggedUser._key ? <Tag
                                        icon='user-online'
                                        className='background-color-status-success-19'
                                        iconClassName='color-status-success-06'
                                        chip={true}>
                                        {user._key}
                                    </Tag> : <p>{user._key}</p>}
                                </TableCell>
                                <TableCell>
                                    <div>{user.info.nome} <strong>{user.info.cognome}</strong></div>
                                </TableCell>
                                <TableCell><Detail>{user.info.email}</Detail></TableCell>
                                <TableCell><Detail>{user.info.telefono}</Detail></TableCell>
                                <TableCell>
                                    <Tag icon={user.ruolo === 'COORDINATORE' ? 'role-admin'
                                               : user.ruolo === 'CUSTODE' ? 'role-subscriber' 
                                               : 'role-editor'}
                                         className='background-color-brand-argo-18'
                                         iconClassName='color-brand-argo-06'
                                         chip={true}>
                                        {user.ruolo}
                                    </Tag>
                                </TableCell>
                                <TableCell>
                                {this.props.loggedUser.ruolo === "COORDINATORE" &&
                                    <Grid columns="2">
                                        <Link to={`/utenti/${user._key}`}><Button variant="info-outline" icon="user"/></Link>
                                    </Grid>
                                }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        )
    }
}

UsersDisabled.propTypes = {
    getUsers: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    users: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    users: state.users,
    loggedUser: state.auth.user
})

export default connect(mapStateToProps, {getUsers, deleteUser})(UsersDisabled)
