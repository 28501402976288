import React, { Children, cloneElement } from 'react'
import PropTypes from 'prop-types'
import './Accordion.css'

import {
  H3,
  Icon
} from '@maggioli-design-system/react'

class AccordionItem extends React.Component{
  render(){
    return(
      <div className={`accordion__item ${this.props.className} ${this.props.isOpened ? 'accordion__item--is-opened' : ''}`}>
        <header className="accordion__header">
          {this.props.icon && <Icon name={this.props.icon} className="accordion__icon"/>}
          <H3 className="accordion__title">{this.props.title}</H3>
          <div className="accordion__toggle" onClick={this.props.onClick}>
            <Icon name={this.props.isOpened ? 'navigation-hide' : 'navigation-show'} className="accordion__action"/>
          </div>
        </header>
        <section className="accordion__body">
          <div className="accordion__content">
            {this.props.children}
          </div>
        </section>
    </div>
    )
  }
}

AccordionItem.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  isOpened: PropTypes.bool,
  title: PropTypes.string,
  onClick: PropTypes.func
}

AccordionItem.defaultProps = {
  className: '',
  isOpened: false,
  onClick: () => {}
}

export class Accordion extends React.Component {

  constructor(props){
    super()
    this.onClick = this.onClick.bind(this)
    this.state = {
      isOpened: Array(React.Children.count(props.children)).fill(false)
    };
  }

  onClick (index) {
    let tmp = this.state.isOpened;
    let initialTmp = tmp[index]
    tmp.fill(false)
    tmp[index] = !initialTmp
    this.setState({
      isOpened: Object.assign([], this.state.isOpened, tmp)
    })
  }

  render(){
    return(
        <div className={`accordion ${this.props.className}`}>
          {
            Children.map(this.props.children, (child, index) => {
              return cloneElement(child, {
                key: index,
                index: index,
                onClick: () => this.onClick(index),
                isOpened: this.state.isOpened[index]
              })
            })
          }
        </div>
    )
  }
}

Accordion.propTypes = {
  className: PropTypes.string,
  initialItem: PropTypes.number
}

Accordion.defaultProps = {
  className: '',
  initialItem: 0,
}

export default Accordion
export {
  AccordionItem,
}
