import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Link} from "react-router-dom";
import {getLocalizzazioni} from '../../actions/localizzazioniActions'
import {clearErrors} from '../../actions/errorActions'
import {clearSuccess} from '../../actions/successActions'

import Loader from '../Loader'
import {
    Grid,
    Card,
    H3,
    Image,
    Tag,
    Button
} from '@maggioli-design-system/react'
import defaultImage from '../../assets/images/default.png'

class Localizzazioni extends Component {

    componentDidMount(){
        this.props.getLocalizzazioni();
    }
    render() {
        const {localizzazioni} = this.props.localizzazioni
        return (
            <div>
                <Loader/>
                <Grid columns="2">
                    {localizzazioni.map(loc =>(
                        <Card key={loc._key} gutter="normal">
                            <Image src={loc.media ? loc.media.immagine : defaultImage} aspectRatio="16:9" aspectRatioPosition="50% 50%"></Image>
                            <H3>{loc.nome}</H3>
                            <Grid columns="3">
                                <Tag chip={true} icon="data-activity-log">Musei: {loc.piani}</Tag>
                                <Tag chip={true} icon="dashboard">Sale: {loc.sale}</Tag>
                                <Link to={`/ronda/${loc._key}`}>
                                    <Button className="bg-brand-argo-10 hover:bg-brand-argo-8" width="fill" borderRadius="xxlarge" icon="read-only">Vedi</Button>
                                </Link>
                            </Grid>
                        </Card>
                    ))}
                </Grid>
            </div>
        )
        
    }
}

Localizzazioni.propTypes = {
    getLocalizzazioni: PropTypes.func.isRequired,
    localizzazioni: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    localizzazioni: state.localizzazioni
})

export default connect(mapStateToProps, {getLocalizzazioni, clearErrors, clearSuccess})(Localizzazioni)
