import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Link} from "react-router-dom";
import { getSegnalazioni } from '../actions/segnalazioniActions'
import {parseData} from '../utils/Utils'

import Loader from './Loader'
import {
    Grid,
    Row,
    Button,
    Card,
    Image,
    Tag,
    H3,
    H4,
    UList,
    UListItem,
    Caption
} from '@maggioli-design-system/react'
import noImage from '../assets/images/default.png'

class Segnalazioni extends Component {

    componentDidMount() {
        this.props.getSegnalazioni()
    }

    render() {
        const {segnalazioni} = this.props.segnalazioni;
        if(segnalazioni && segnalazioni.length > 0){
            return (
                <div>
                    <Loader/>
                    <Grid>
                        <Row lastChild="to-right" gutter="xsmall">
                            <></>
                            <Row>
                                <Button size="small" onClick={()=>{this.props.getSegnalazioni()}} borderRadius="large" icon="data-filtered" variant="secondary-outline">Tutte</Button>
                                <Button size="small" onClick={()=>{this.props.getSegnalazioni('mie')}} borderRadius="large" icon="data-filtered" variant="secondary-outline">Mie</Button>
                                <Button size="small" onClick={()=>{this.props.getSegnalazioni('aperte')}} borderRadius="large" icon="status-warning" variant="error-outline">Aperte</Button>
                                <Button size="small" onClick={()=>{this.props.getSegnalazioni('incarico')}} borderRadius="large" icon="help-support" variant="warning-outline">Prese in carico</Button>
                                <Button size="small" onClick={()=>{this.props.getSegnalazioni('chiuse')}} borderRadius="large" icon="crud-save" variant="info-outline">Risolte</Button>
                            </Row>
                        </Row>
                    
                        <Grid columns="3" gutter="xlarge">
                            { segnalazioni.map(s => (
                                <Card gutter="large" key={s._key} className={s.convalida === 1 ? 
                                    "background-color-status-info-19 box-shadow-soft grid--tmpl-segnalazione" : 
                                    (s.stati.length === 1 ? "background-color-status-error-19 box-shadow-soft grid--tmpl-segnalazione" : 
                                    "background-color-status-warning-19 box-shadow-soft grid--tmpl-segnalazione")} >
                                    <Grid gutter="xsmall" columns="2" >
                                        <Image src={s.oggetto.media ? s.oggetto.media : noImage} aspectRatio="1:1" aspectRatioPosition="50% 50%" />
                                        <Fragment>
                                            <Grid gutter="large" className="text-secondary text-secondary--detail">
                                                {s.stati.length === 1 ? 
                                                    <Grid>
                                                        <Tag chip={true} icon="status-warning" className={`background-color-status-error-17 color-status-error-04`}>Aperta</Tag>
                                                        <Grid gutter="xsmall">
                                                            <Tag icon="input-calendar" size="xsmall"><Caption>{parseData(s.stati[0].data).data}</Caption></Tag>
                                                            <Tag icon="input-time" size="xsmall"><Caption>{parseData(s.stati[0].data).ora}</Caption></Tag>
                                                        </Grid>
                                                        
                                                    </Grid> : (s.stati.length === 2 ? 
                                                    <Grid>
                                                        <Tag chip={true} icon="help-support" className={`background-color-status-warning-17 color-status-warning-04`}>In carico</Tag>
                                                        <Grid gutter="xsmall">
                                                            <Tag icon="input-calendar" size="xsmall"><Caption>{parseData(s.stati[0].data).data}</Caption></Tag>
                                                            <Tag icon="input-time" size="xsmall"><Caption>{parseData(s.stati[0].data).ora}</Caption></Tag>
                                                        </Grid> 
                                                    </Grid>
                                                    : 
                                                    <Grid>
                                                        <Tag chip={true} icon="crud-save" className={`background-color-status-info-17 color-status-info-04`}>Risolta</Tag>
                                                        <Grid gutter="xsmall">
                                                            <Tag icon="input-calendar" size="xsmall"><Caption>{parseData(s.stati[0].data).data}</Caption></Tag>
                                                            <Tag icon="input-time" size="xsmall"><Caption>{parseData(s.stati[0].data).ora}</Caption></Tag>
                                                        </Grid>
                                                    </Grid>)
                                                }
                                            </Grid>
                                        </Fragment>
                                    </Grid>
                                    <Grid gutter="xsmall">
                                            <H4>{s.oggetto.nome}</H4>
                                            <UList>
                                                {s.danni ? s.danni.map(d => (
                                                    <UListItem icon="status-error" iconSize="small" iconClassName="color-status-error-08" key={d} className="text-secondary text-secondary--detail">{d}</UListItem>
                                                )) : null}
                                            </UList>
                                    </Grid>
                                    <Link to={`/segnalazioni/${s._key}`}>
                                        <Button width="fill" round icon="read-only" variant="secondary" className={s.convalida === 1 ? "background-color-status-info-04 box-shadow-soft" : (s.stati.length === 1 ? "background-color-status-error-04 box-shadow-soft" : "background-color-status-warning-04 box-shadow-soft")}>Vedi Segnalazione</Button>
                                    </Link>
                                </Card>
                            ))}
                        </Grid>
                    </Grid>
                </div>
            )
        } else{
            return (
                <div>
                    <Loader/>
                    <Grid>
                        <Row lastChild="to-right" gutter="large">
                            <></>
                            <Row>
                                <Button size="small" onClick={()=>{this.props.getSegnalazioni()}} borderRadius="large" icon="data-filtered" variant="secondary-outline">Tutte</Button>
                                <Button size="small" onClick={()=>{this.props.getSegnalazioni('mie')}} borderRadius="large" icon="data-filtered" variant="secondary-outline">Mie</Button>
                                <Button size="small" onClick={()=>{this.props.getSegnalazioni('aperte')}} borderRadius="large" icon="data-filtered" variant="secondary-outline">Aperte</Button>
                                <Button size="small" onClick={()=>{this.props.getSegnalazioni('incarico')}} borderRadius="large" icon="data-filtered" variant="secondary-outline">Prese in carico</Button>
                                <Button size="small" onClick={()=>{this.props.getSegnalazioni('chiuse')}} borderRadius="large" icon="data-filtered" variant="secondary-outline">Risolte</Button>
                            </Row>
                        </Row>
                        <Row>
                            <H3>Nessuna segnalazione presente</H3>
                        </Row>
                    </Grid>
                </div>
            )
        }
    }
}

Segnalazioni.propTypes = {
    getSegnalazioni: PropTypes.func.isRequired,
    segnalazioni: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    segnalazioni: state.segnalazioni
});

export default connect(mapStateToProps, {getSegnalazioni})(Segnalazioni)