import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import usersReducer from './usersReducer';
import userReducer from './userReducer';
import errorReducer from './errorReducer';
import successReducer from './successReducer'
import authReducer from './authReducer';
import segnalazioniReducer from './segnalazioniReducer'
import segnalazioneReducer from './segnalazioneReducer'
import pianteReducer from './pianteReducer'
import salaReducer from './salaReducer'
import localizzazioniReducer from './localizzazioniReducer'
import pageReducer from './pageReducer'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth']
}

const rootReducer = combineReducers({
    auth: authReducer,
    success: successReducer,
    error: errorReducer,
    users: usersReducer,
    user: userReducer,
    segnalazioni: segnalazioniReducer,
    segnalazione: segnalazioneReducer,
    localizzazioni: localizzazioniReducer,
    piante: pianteReducer,
    sala: salaReducer,
    page: pageReducer
})


export default persistReducer(persistConfig, rootReducer);