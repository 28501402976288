import {
    PAGE_SWITCH
} from '../actions/types';

const initialState = {
    page: 'Amministrazione'
}

export default function authReducer(state = initialState, action) {

    switch(action.type){

        case PAGE_SWITCH:
            return {
                ...state,
                page: action.payload
            };
        default:
            return state
    }
}