import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Link} from "react-router-dom"
import { getSala, orderOpereList, moveOpera } from '../../actions/salaActions'
import {clearErrors} from '../../actions/errorActions';
import {clearSuccess} from '../../actions/successActions';
import Loader from '../Loader';
import ContenitoriSortable from './ContenitoriSortable'

import {
    Banner,
    Tag,
    Hr,
    Row
} from '@maggioli-design-system/react'

class Ronda extends Component {

    state = {
        message: null,
        success: null
    }

    componentDidMount(){
        this.props.getSala(this.props.match.params._keysala);
    }

    componentDidUpdate(prevProps) {
        const {error} = this.props;
        const {success} = this.props;
        if(error !== prevProps.error){
            if(error.id === 'RONDA_ERROR'){
                this.setState({message: error.message.message})
            } else {
                this.setState({message: null})
            }
        }
        if(success !== prevProps.success){

            if(success.id === 'RONDA_UPDATE_SUCCESS'){
                this.setState({success: success.message})
            } else {
                this.setState({success: null})
            }
        }
    }

    renderMessage = () => {
        if(this.state.message){
            return <Banner status="error">{this.state.message}</Banner>
        }
        else if(this.state.success){
            return <Banner status="success">{this.state.success}</Banner>
        }
    }

    renderSala = (piante, _keysala) => {
        let res
        piante.forEach(pianta => {
            if(pianta.sale.filter(s => s._key === _keysala ).length > 0){
                res = {
                    pianta: pianta.nome,
                    sala: pianta.sale.filter(s => s._key === _keysala )[0].nome
                }
                
            }
        })
        return res
    }

    render() {
        const {sala} = this.props.sala
        const {localizzazioni} = this.props.localizzazioni
        const {_key} = this.props.match.params
        const {_keysala} = this.props.match.params
        const {piante} = this.props.piante
        const loc = localizzazioni.filter(l => l._key === _key)[0].nome
        let infos = this.renderSala(piante, _keysala)
        
        if(sala){
            
            return (
                <div>
                    <Loader/>
                    <Row>
                        <Link to="/ronda" ><Tag chip className="bg-brand-argo-16 hover:bg-brand-argo-14">{loc}</Tag></Link>
                        <Link to={"/ronda/" + _key}><Tag chip className="bg-brand-argo-16 hover:bg-brand-argo-14">{infos.pianta}</Tag></Link>
                        <Tag chip className="bg-brand-argo-16 hover:bg-brand-argo-14">{infos.sala}</Tag>
                    </Row>
                    <Hr />
                    {this.renderMessage()}
                    <ContenitoriSortable data={sala} onSortEnd={this.props.orderOpereList} onMove={this.props.moveOpera} />
                </div>
            )
        }else{
           return (<h1>Sala vuota</h1>)
        }
            
        
    }
}

Ronda.propTypes = {
    getSala: PropTypes.func.isRequired,
    orderOpereList: PropTypes.func.isRequired,
    moveOpera: PropTypes.func.isRequired,
    sala: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    sala: state.sala,
    localizzazioni: state.localizzazioni,
    piante: state.piante
});

export default connect(mapStateToProps, {getSala, orderOpereList, moveOpera, clearErrors, clearSuccess})(Ronda)
